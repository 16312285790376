import "regenerator-runtime/runtime";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Big from "big.js";
import Form from "./components/Form";

const BOATLOAD_OF_GAS = Big(3).times(10 ** 13).toFixed();

const App = ({ contract, currentUser, nearConfig, wallet }) => {
  const [status, setStatus] = useState(null);

  useEffect(async () => {
    if (currentUser) {
      const status = await contract.get_data({
        account_id: currentUser.accountId
      });

      setStatus("Api Key: " + status[0] + " || " + "Site Id: " + status[1]);
    }
  });

  const onSubmit = async event => {
    event.preventDefault();

    const fieldset = event.target.elements.fieldset;
    const apiKey = event.target.elements.apiKey;
    const siteId = event.target.elements.siteId;

    fieldset.disabled = true;
debugger;
    await contract.set_data(
      {
        api_key: apiKey.value,
        site_id: siteId.value,
        account_id: currentUser.accountId
      },
      BOATLOAD_OF_GAS
    );

      debugger;

    const status = await contract.get_data({
      account_id: currentUser.accountId
    });

    setStatus("Api Key: " + status[0] + " || " + "Site Id: " + status[1]);

    apiKey.value = "";
    siteId.value = "";
    fieldset.disabled = false;
    apiKey.focus();
  };

  const signIn = () => {
    wallet.requestSignIn(
      {contractId: nearConfig.contractName, methodNames: ['set_data']},
      "Mira Network PoP proof of concept"
    );
  };

  const signOut = () => {
    wallet.signOut();
    window.location.replace(window.location.origin + window.location.pathname);
  };

  return (
    <main>
      <header>
        <h1>Mira Network - PoP proof of concept</h1>

        {currentUser ?
          <p>Currently signed in as: <code>{currentUser.accountId}</code></p>
        :
          <p>Join our clean power production program! Please login to continue.</p>
        }

        { currentUser
          ? <button onClick={signOut}>Log out</button>
          : <button onClick={signIn}>Log in</button>
        }
      </header>

      {currentUser &&
        <Form
          onSubmit={onSubmit}
          currentUser={currentUser}
        />
      }

      {status ?
        <>
          <p>Your current settings:</p>
          <p>
          
              {status}
           
          </p>
        </>
      :
        <p>Please register your system</p>
      }
    </main>
  );
};

App.propTypes = {
  contract: PropTypes.shape({
    set_data: PropTypes.func.isRequired,
    get_data: PropTypes.func.isRequired
  }).isRequired,
  currentUser: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    balance: PropTypes.string.isRequired
  }),
  nearConfig: PropTypes.shape({
    contractName: PropTypes.string.isRequired
  }).isRequired,
  wallet: PropTypes.shape({
    requestSignIn: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired
  }).isRequired
};

export default App;
